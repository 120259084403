

import firebase from 'firebase/compat/app';
import 'firebase/compat/database'
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getFirestore } from "firebase/firestore";
import "firebase/firestore";
import { getStorage } from "firebase/storage";



const app = firebase.initializeApp({
  apiKey: "AIzaSyBamMJdJ53fQEZrz9RMqDz1PuPmGl7357s",
  authDomain: "func-d42df.firebaseapp.com",
  projectId: "func-d42df",
  storageBucket: "func-d42df.appspot.com",
  messagingSenderId: "380270343572",
  appId: "1:380270343572:web:662ce140ff84163240cb81",
  measurementId: "G-N1ZN27J4Z5"
});

export const db = getFirestore(app);
export const storage = getStorage(app);
const auth = firebase.auth()

export default app

export { auth  };
