import React from "react";
import './Terms.css'

const Privacy = () => {
  return (
    <div className="bg-conn">
      <h1>Privacy Policy </h1>     
      <div className="ngh-1">
        
        <div className="ngf-com1">
          <p>
            Protein Cartel is committed to protecting the privacy of its users.
            This Privacy Policy explains how we collect, use, and protect the
            personal information that we collect through our website. By using
            our website, you consent to the terms of this Privacy Policy.
          </p>
          <h1>Information Collection</h1>
          <p>
            We may collect personal information from you when you visit our
            website, register an account, place an order, or subscribe to our
            newsletter. This information may include your name, email address,
            mailing address, phone number, and payment information.
          </p>
          <p>
            We may also collect non-personal information, such as your IP
            address, browser type, and operating system.
          </p>
          <h1>Use of Information</h1>
          <p>
            We use the information we collect to provide you with the products
            and services you have requested. This may include processing your
            order, shipping your products, and sending you newsletters.
          </p>
          <p>
            We may also use your information to improve our website, respond to
            your customer service requests, and communicate with you about our
            products and promotions.
          </p>
          <p>
            We may share your personal information with third-party service
            providers who perform services on our behalf, such as shipping and
            payment processing.
          </p>
          <p>
            We do not sell or rent your personal information to third parties
            for marketing purposes.
          </p>
          <h1>Security</h1>
          <p>
            We take reasonable measures to protect the security of your personal
            information. We use industry-standard encryption to protect your
            payment information, and we restrict access to your personal
            information to authorized personnel only.
          </p>
          <p>
            However, no system can be completely secure, and we cannot guarantee
            the security of your personal information.
          </p>
          <h1>Cookies</h1>
          <p>
            We use cookies to improve your browsing experience and to
            personalize the content and advertisements that you see on our
            website. You can choose to disable cookies in your browser settings,
            but this may affect your ability to use our website.
          </p>
          <h1>Changes to This Policy</h1>
          <p>
            We reserve the right to modify this Privacy Policy at any time. Any
            changes will be posted on our website, and the updated Privacy
            Policy will be effective immediately.
          </p>
          <h1>Contact Us</h1>
          <p>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at info@proteincartel.com.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
