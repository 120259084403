import { configureStore } from '@reduxjs/toolkit';
import { save, load } from 'redux-localstorage-simple';
import basketReducer from './components/Redux/basketSlice';

const preloadedState = load();

export const store = configureStore({
  reducer: {
    basket: basketReducer,
  },
  preloadedState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(save()),
});

export default store;