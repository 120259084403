import React from "react";
import DragScroll from "react-indiana-drag-scroll";

const Related = () => {
  return (
    <div className="related-section-main">
    <div className="related-card">
        <img src="https://static.nike.com/a/images/c_limit,w_592,f_auto/t_product_v1/u_126ab356-44d8-4a06-89b4-fcdcc8df0245,c_scale,fl_relative,w_1.0,h_1.0,fl_layer_apply/5b15af60-d252-4f49-b5de-5f1e1a981df2/jordan-flight-essentials-t-shirt-JRFdw8.png" />
        <div className="related-con-c1">
          <h1>Nike Sportswear Trend</h1>
          <h2>MRP : ₹ 3 995.00</h2>
        </div>
      </div>
      <div className="related-card">
        <img
          src="
        
          https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/2fec9ab5-41d4-467b-b36c-e7a69fa40d8e/sportswear-trend-short-sleeve-top-wPH3tD.png"
        />
        <div className="related-con-c1">
          <h1>Nike Sportswear Trend</h1>
          <h2>MRP : ₹ 3 995.00</h2>
        </div>
      </div>
      <div className="related-card">
        <img
          src="
          https://static.nike.com/a/images/c_limit,w_592,f_auto/t_product_v1/u_126ab356-44d8-4a06-89b4-fcdcc8df0245,c_scale,fl_relative,w_1.0,h_1.0,fl_layer_apply/f9c0ac07-4e86-4e5f-a54d-b317b283c798/jordan-t-shirt-0mrwm6.png"
        />
        <div className="related-con-c1">
          <h1>Nike Sportswear Trend</h1>
          <h2>MRP : ₹ 3 995.00</h2>
        </div>
      </div>
      <div className="related-card">
        <img
          src="
          https://static.nike.com/a/images/c_limit,w_592,f_auto/t_product_v1/u_126ab356-44d8-4a06-89b4-fcdcc8df0245,c_scale,fl_relative,w_1.0,h_1.0,fl_layer_apply/fb8d8ab6-a232-4813-876b-67d32ad41233/jordan-sport-graphic-t-shirt-0PM7k2.png
          "
        />
        <div className="related-con-c1">
          <h1>Nike Sportswear Trend</h1>
          <h2>MRP : ₹ 3 995.00</h2>
        </div>
      </div>
      <div className="related-card">
        <img
          src="
   https://static.nike.com/a/images/c_limit,w_592,f_auto/t_product_v1/u_126ab356-44d8-4a06-89b4-fcdcc8df0245,c_scale,fl_relative,w_1.0,h_1.0,fl_layer_apply/b8c3c7c7-ed10-4c3b-9344-05251af646f2/jordan-23-engineered-t-shirt-6fXhgT.png
          "
        />
        <div className="related-con-c1">
          <h1>Nike Sportswear Trend</h1>
          <h2>MRP : ₹ 3 995.00</h2>
        </div>
      </div>
      <div className="related-card">
        <img
          src="
          https://static.nike.com/a/images/c_limit,w_592,f_auto/t_product_v1/u_126ab356-44d8-4a06-89b4-fcdcc8df0245,c_scale,fl_relative,w_1.0,h_1.0,fl_layer_apply/6bde9e9b-4154-4b3f-b093-615bb493505c/jordan-flight-essentials-oversized-t-shirt-C7XVkQ.png
          "
        />
        <div className="related-con-c1">
          <h1>Nike Sportswear Trend</h1>
          <h2>MRP : ₹ 3 995.00</h2>
        </div>
      </div>        



    </div> 
  );
};

export default Related;


