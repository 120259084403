import React, { useState, usesize } from "react";
import { useNavigate } from "react-router-dom";

const Productlistcards = () => {
  const navigation = useNavigate();
  const [selectedsize, setSelectedsize] = useState("");

  const handlesizeChange = (e) => {
    setSelectedsize(e.target.value);
  };

  // An array containing all Indian sizes
  const sizes = ["S", "M", "L", "XL"];

  return (
    <div className="cards-section-cat-home">
      <div className="card-rel-c1">
        <div className="card-car-ab-1">
          <div className="card-select-size">
            <select
              id="sizeSelect"
              value={selectedsize}
              onChange={handlesizeChange}
            >
              <option value=""> Select size</option>
              {sizes.map((size, index) => (
                <option key={index} value={size}>
                  Size:{size}
                </option>
              ))}
            </select>
          </div>
          <button className="add-card-btn-1">Add</button>
        </div>
        <div
          className="card-cat-1"

          onClick={() =>
             navigation("/ProductPreview")}
        >
          <div className="card-cat-re">
            <img
              className="card-cat-img-1"
              src="https://static.nike.com/a/images/c_limit,w_592,f_auto/t_product_v1/u_126ab356-44d8-4a06-89b4-fcdcc8df0245,c_scale,fl_relative,w_1.0,h_1.0,fl_layer_apply/5b15af60-d252-4f49-b5de-5f1e1a981df2/jordan-flight-essentials-t-shirt-JRFdw8.png"
              alt=""
            />
          </div>
          <div className="related-con-c1">
            <h1>Nike Sportswear Trend</h1>
            <h2>MRP : ₹ 3 995.00</h2>
          </div>
        </div>
      </div>
      <div className="card-rel-c1">
        <div className="card-car-ab-1">
          <div className="card-select-size">
            <select
              id="sizeSelect"
              value={selectedsize}
              onChange={handlesizeChange}
            >
              <option value=""> Select size</option>
              {sizes.map((size, index) => (
                <option key={index} value={size}>
                  Size:{size}
                </option>
              ))}
            </select>
          </div>
          <button className="add-card-btn-1">Add</button>
        </div>
        <div
          className="card-cat-1"

          onClick={() =>
             navigation("/ProductPreview")}
        >
          <div className="card-cat-re">
            <img
              className="card-cat-img-1"
              src="https://static.nike.com/a/images/c_limit,w_592,f_auto/t_product_v1/u_126ab356-44d8-4a06-89b4-fcdcc8df0245,c_scale,fl_relative,w_1.0,h_1.0,fl_layer_apply/5b15af60-d252-4f49-b5de-5f1e1a981df2/jordan-flight-essentials-t-shirt-JRFdw8.png"
              alt=""
            />
          </div>
          <div className="related-con-c1">
            <h1>Nike Sportswear Trend</h1>
            <h2>MRP : ₹ 3 995.00</h2>
          </div>
        </div>
      </div>
      <div className="card-rel-c1">
        <div className="card-car-ab-1">
          <div className="card-select-size">
            <select
              id="sizeSelect"
              value={selectedsize}
              onChange={handlesizeChange}
            >
              <option value=""> Select size</option>
              {sizes.map((size, index) => (
                <option key={index} value={size}>
                  Size:{size}
                </option>
              ))}
            </select>
          </div>
          <button className="add-card-btn-1">Add</button>
        </div>
        <div
          className="card-cat-1"

          onClick={() =>
             navigation("/ProductPreview")}
        >
          <div className="card-cat-re">
            <img
              className="card-cat-img-1"
              src="https://static.nike.com/a/images/c_limit,w_592,f_auto/t_product_v1/u_126ab356-44d8-4a06-89b4-fcdcc8df0245,c_scale,fl_relative,w_1.0,h_1.0,fl_layer_apply/5b15af60-d252-4f49-b5de-5f1e1a981df2/jordan-flight-essentials-t-shirt-JRFdw8.png"
              alt=""
            />
          </div>
          <div className="related-con-c1">
            <h1>Nike Sportswear Trend</h1>
            <h2>MRP : ₹ 3 995.00</h2>
          </div>
        </div>
      </div>
      <div className="card-rel-c1">
        <div className="card-car-ab-1">
          <div className="card-select-size">
            <select
              id="sizeSelect"
              value={selectedsize}
              onChange={handlesizeChange}
            >
              <option value=""> Select size</option>
              {sizes.map((size, index) => (
                <option key={index} value={size}>
                  Size:{size}
                </option>
              ))}
            </select>
          </div>
          <button className="add-card-btn-1">Add</button>
        </div>
        <div
          className="card-cat-1"

          onClick={() =>
             navigation("/ProductPreview")}
        >
          <div className="card-cat-re">
            <img
              className="card-cat-img-1"
              src="https://static.nike.com/a/images/c_limit,w_592,f_auto/t_product_v1/u_126ab356-44d8-4a06-89b4-fcdcc8df0245,c_scale,fl_relative,w_1.0,h_1.0,fl_layer_apply/5b15af60-d252-4f49-b5de-5f1e1a981df2/jordan-flight-essentials-t-shirt-JRFdw8.png"
              alt=""
            />
          </div>
          <div className="related-con-c1">
            <h1>Nike Sportswear Trend</h1>
            <h2>MRP : ₹ 3 995.00</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Productlistcards;
