import React, { useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { addToBasket, basketTotal } from "../../components/Redux/basketSlice";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Currency from "react-currency-formatter";
import Skeleton from "react-loading-skeleton";

const getDatafromLS = () => {
  const data = localStorage.getItem("Wishlist");
  if (data) {
    return JSON.parse(data);
  } else {
    return [];
  }
};

const ProductDetail = ({ item, loading }) => {
  const size = ["s", "m", "l", "xl", "xxl"];

  const [selectedsize, setSelectedsize] = useState();
  const selectsize = (data) => {
    setSelectedsize(data);
  };

  const color = ["black", "white"];

  const [delivery, setDelivery] = useState(false);

  const Icons = delivery ? MdKeyboardArrowUp : MdKeyboardArrowDown;
  const [review, setReview] = useState(false);

  const Icons1 = review ? MdKeyboardArrowUp : MdKeyboardArrowDown;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addItemToBasket = () => {
    dispatch(
      addToBasket({
        id: item?.id,
        productname: item?.name,
        price: item?.price,
        image: item?.image,
        category: item?.category,
        type: item?.type,
        size: selectedsize,
      })
    );
    navigate("/Cart");
    toast.success("Added to Bag");
  };

  const [Wishlist, setWishlist] = useState(getDatafromLS());

  const handleWish = (e) => {
    // Creating an object
    const newWish = {
      id: item?.id,
      productname: item?.name,
      price: item?.price,
      image: item?.image,
      category: item?.category,
      type: item?.type,
      size: selectedsize,
    };

    // Check if an item with the same id already exists in the wishlist
    const isDuplicate = Wishlist.some((wish) => wish.id === newWish.id);

    if (!isDuplicate) {
      // Add the new wish to the wishlist only if it's not a duplicate
      setWishlist([...Wishlist, newWish]);
      toast.success("Added to Wishlist");
    } else {
      toast.success("Item is already in the Wishlist");
    }
  };

  useEffect(() => {
    localStorage.setItem("Wishlist", JSON.stringify(Wishlist));
  }, [Wishlist]);

  const [loading1, setIsLoading] = useState(true);

  return (
    <div className="rh-pro-view-sec">
      {loading ? (
        <div className="pro-r-side-content">
          <div className="pro-details-head">
            <h1 className="pro-r-h1">     <Skeleton count={1} /></h1>
            <h1 className="pro-r-h2">
            <Skeleton count={1} />

            </h1>
          </div>

          <div className="size-pro-v">
            <div className="size-pro-v">
              <div className="size-pro-v1">
                <span>Select Color</span>
                <span></span>
              </div>
              <div className="size-btn-pro-v1">
              <Skeleton width={50} height={50}    />
              <Skeleton width={50} height={50}    />

              </div>
            </div>
            <div className="size-pro-v1">
              <span>Select Size</span>
              <span>Size Guide</span>
            </div>
            <div className="size-btn-pro-v1">
            <Skeleton width={50} height={50}    />
              <Skeleton width={50} height={50}    />
              <Skeleton width={50} height={50}    />
              <Skeleton width={50} height={50}    />
              
            </div>
          </div>

   
          <Skeleton height={50}    />

          <Skeleton height={50}    />
          <Skeleton height={50}    />
          <Skeleton height={50}    />



   
        </div>
      ) : (
        <div className="pro-r-side-content">
          <div className="pro-details-head">
            <h1 className="pro-r-h1">{item?.name}</h1>
            <h1 className="pro-r-h2">
              MRP : <Currency quantity={item?.price} currency="INR" />
            </h1>
          </div>

          <div className="size-pro-v">
            <div className="size-pro-v">
              <div className="size-pro-v1">
                <span>Select Color</span>
                <span></span>
              </div>
              <div className="size-btn-pro-v1">
                {color.map((item) => (
                  <button
                    style={{ background: item }}
                    className="color-select-pro-v"
                  ></button>
                ))}
              </div>
            </div>
            <div className="size-pro-v1">
              <span>Select Size</span>
              <span>Size Guide</span>
            </div>
            <div className="size-btn-pro-v1">
              {size.map((item) => (
                <button
                  className={
                    selectedsize === item
                      ? "size-select-pro-v2"
                      : "size-select-pro-v"
                  }
                  onClick={() => selectsize(item)}
                >
                  {item}
                </button>
              ))}
            </div>
          </div>

          <div className="buy-add-btns-pro-1">
            <button onClick={() => addItemToBasket()}>Buy</button>
            <button onClick={() => handleWish()}>Favourite</button>
          </div>

          <p className="description-pro-section">{item?.description}</p>

          <div className="reviews-delivery-section">
            <div className="del-sec1">
              <div className="del-sec-2" onClick={() => setDelivery(!delivery)}>
                <span>Delivery & Returns</span>
                <Icons color="black" size={30} />
              </div>
              {delivery && (
                <p>
                  Orders are processed and delivered Monday–Friday (excluding
                  public holidays)
                </p>
              )}
            </div>
            <div className="del-sec3">
              <div className="del-sec-2" onClick={() => setReview(!review)}>
                <span>Reviews(0)</span>
                <Icons1 color="black" size={30} />
              </div>
              {review && (
                <p>
                  Orders are processed and delivered Monday–Friday (excluding
                  public holidays)
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetail;
