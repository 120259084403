import React from "react";
import "./Favourites.css";
import Favcards from "./Favcards";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
const Favourites = () => {
  const navigate = useNavigate();
  const getDatafromLS = () => {
    const data = localStorage.getItem("Wishlist");
    if (data) {
      return JSON.parse(data);
    } else {
      return [];
    }
  };
  const [wishlist, setWishlist] = useState(getDatafromLS());

  // saving data to local storage
  useEffect(() => {
    localStorage.setItem("Wishlist", JSON.stringify(wishlist));
  }, [wishlist]);

  const deleteBook = (id) => {
    const filteredBooks = wishlist.filter((element, index) => {
      return element.id !== id;
    });
    setWishlist(filteredBooks);
  };
  console.log(wishlist);

  return (
    <div className="fav-f1">
      <div className="fav-f2">
        <div>
          <h1 className="fav-head">Favourites</h1>
          {wishlist?.length >0 ?  <h1 className="fav-head2">{wishlist?.length} Item</h1> :
         ''
          
          }
        </div>
        {wishlist?.length > 0 ? (
          <div className="fav-f3">
            {wishlist.map((item, id) => (
              <Favcards item={item} deleteBook={deleteBook} />
            ))}
          </div>
        ) : (
          <div className="wish-empty-d">
            <h1>SAVE YOUR FAVOURITE ITEMS</h1>
            <p>
              Want to save the items that you love? Just click on the heart
              symbol beside the item and it will show up here.
            </p>
            <Link to={"/"}>
              <button className="wish-emp-btn">Browse now</button>
            </Link>
          </div>
        )}
        </div>
      </div>

  );
};

export default Favourites;
