import React from "react";
import "./Contact.css";
const Contact = () => {
  return (
    <div>
      <div className="contact-c1">
        <div className="contact-c2">
          <div className="contact-c3">
            <p className="contact-para">
              You can call with our Virtual assistant 24/7 for answers to
              frequently asked questions. You’ll be put through to a live agent
              if you need more help, during below opening hours. Call us
            </p>
            <div className="contact-d4">

            <div className="contact-span-section">
              <span>Call us</span>
              <span>000-800-050-1633</span>
            </div>
            <div className="contact-span-section">

              <span>Opening Hours</span>
              <span style={{fontWeight:'bold'}}>Phone</span>
              <span>Monday – Saturday: 8.00 – 22.00</span>
            </div>
            <div className="contact-span-section">

              <span>Email</span>
              <span>customerservice.@func.com</span>
            </div>
          </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
