import React, { useEffect, useMemo, useState } from "react";
import "./Cart.css";
import { BsTrash3 } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";

import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../components/Firebase/Firebase";
import toast from "react-hot-toast";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import Currency from "react-currency-formatter";
import {
  removeFromBasket,
  selectBasketItems,
  selectBasketTotal,
} from "../../components/Redux/basketSlice";
import { BeatLoader } from "react-spinners";
import { doc, getDoc } from "firebase/firestore";

const Cart = () => {
  const [selectedNumber, setSelectedNumber] = useState(1);
  const [error, setError] = useState("");

  const handleChange = (event) => {
    const inputNumber = event.target.value;

    if (inputNumber === "" || (inputNumber >= 1 && inputNumber <= 10)) {
      setSelectedNumber(inputNumber);
      setError("");
    } else {
      setSelectedNumber("");
      setError("Please select a number between 1 and 10.");
    }
  };
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const dispatch = useDispatch();



  
  const [isLoading, setIsLoading] = useState(true);

  const items = useSelector(selectBasketItems);
  const [groupedItemsInBasket, setGroupedItemsInBasket] = useState([]);

  useMemo(() => {
    const groupedItems = items.reduce((results, item) => {
      (results[item.id] = results[item.id] || []).push(item);

      return results;
    }, {});

    setGroupedItemsInBasket(groupedItems);
    setIsLoading(false)
  }, [items]);



  const basketTotal = useSelector(selectBasketTotal);








  const [inputcoupon, setinputcoupon] = useState("");
  const [checkcoupon, setcheckcoupon] = useState("");
  const [totalAmount, setTotalAmount] = useState();
  const [subtotal,setSubtotal]=useState(basketTotal);

  const [coupontry, setCoupontry] = useState(false);

  const onClickCoupon = () => {
    setCoupontry(true);

    getDoc(doc(db, "coupon", inputcoupon)).then((docSnap) => {
      if (docSnap.exists()) {
        setTotalAmount(
          totalAmount -
            (parseInt(docSnap.data()?.discount) / 100) * parseInt(totalAmount)
        );
        console.log("Document data:", docSnap.data());
        setcheckcoupon(docSnap.data());
        toast.success("Coupon  added successfully");
      } else {
        setTotalAmount(totalAmount);
        toast.error("Discount code is not valid");
      }
      setinputcoupon("");
      setIsOpen(false);
    });
  };

  useEffect(() => {
    setTotalAmount(basketTotal)
  }, [basketTotal])







  if (isLoading) {
    return (
      <>
        <div className="loader-sp">
          <BeatLoader size={15} color={"black"} loading={isLoading} />
        </div>
      </>
    );
  }






  

  return (
    <>
      <div className="cart-d1">
        <div className="cart-d2">
          <h1 className="shop-bag-h1">Shopping bag</h1>
          {items.length > 0 && (
            <h1 className="fav-head2">{items.length} item</h1>
          )}

          {items.length > 0 ? (
            <div className="cart-lh-rh-section">
              {Object.entries(groupedItemsInBasket).map(([key, items]) => (
                <div key={key} className=" cart-d31">
                  <div className="cart-d3">
                    <div className="left-h-cart">
                      <img
                        className="cart-product-img"
                        src={items[0]?.image}
                        alt=""
                      />
                      <div className="car-pro-det1">
                        <div className="cart-pro-det">
                          <h1 className="pro-name-cart">
                            {items[0]?.productname}
                          </h1>
                          <h1 className="pro-price-cart">
                            <Currency
                              quantity={items[0]?.price}
                              currency="INR"
                            />
                          </h1>
                        </div>
                        <div>
                          <div className="cart-pro-det2">
                            <div>
                              <div className="cart-card-inner-det">
                                <span>Quantity:</span>
                                <span>{items.length}</span>
                              </div>

                              <div className="cart-card-inner-det">
                                <span>Size:</span>
                                <span style={{ textTransform: "uppercase" }}>
                                {items.map((item) => item.size).join(", ")}
                                </span>
                              </div>
                            </div>
                            <div>
                              <div className="cart-card-inner-det">
                                <span>Colour:</span>
                                <span>
                   

                                </span>
                              </div>
                              <div className="cart-card-inner-det">
                                <span>Total:</span>
                                <span>
                                  <Currency
                                    quantity={items[0]?.price * items.length}
                                    currency="INR"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="right-h-cart">
                      <div>
                        <BsTrash3
                          onClick={() =>
                            dispatch(removeFromBasket({ id: key }))
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="cart-d41">
                <div className="cart-d4">
                  <div className="cart-d5">
                    <div className="rh-cart-t">
                      <div className="rh-cart-t1">
                        <span>Discounts</span>
                        {checkcoupon?   
                        
                        <span >{checkcoupon?.discount}%</span>
                        
                        : 
                        <span onClick={openModal}>Apply discount</span>
                        
                        }
                        

                      </div>
                    </div>
                    {user ? (
                      ""
                    ) : (
                      <div className="rh-cart-t2">
                        <span>Log in to use your personal offers!</span>
                        <button onClick={() =>navigate("/Login",{state:{ login:'cartpage', }})}>
                          Login
                        </button>
                      </div>
                    )}

                    <div className="rh-cart-t4">
                      <span>Order value</span>
                      <span>
                        <Currency quantity={basketTotal} currency="INR" />
                      </span>
                    </div>
                    <div className="rh-cart-t4">
                      <span>Delivery</span>
                      <span>
                        <Currency quantity={100} currency="INR" />
                      </span>
                    </div>
                    <div className="rh-cart-t5">
                      <span>Total</span>
                      <span>
                        <Currency quantity={totalAmount + 100} currency="INR" />
                      </span>
                    </div>
                    {user ? (
                      <div className="rh-cart-t6">
                        <button onClick={() => navigate("/Checkout",{state:{ total:totalAmount, discount:checkcoupon?.discount}})}>
                          Continue to checkout
                        </button>
                      </div>
                    ) : (
                      <div className="rh-cart-t7">
                        <button onClick={() => toast.error("Login First")}>
                          Continue to checkout(Login First)
                        </button>
                      </div>
                    )}

                    <div className="rh-cart-t7">
                      <span className="rh-tt-span">We accept</span>
                      <div className="rh-we-acc-icons">
                        <span className="we-acc-ions">
                          <img
                            src="https://cdn0.iconfinder.com/data/icons/shift-ecommerce/32/Master_Card-64.png"
                            alt=""
                          />
                        </span>
                        <span className="we-acc-ions">
                          <img
                            src="  https://cdn4.iconfinder.com/data/icons/flat-brand-logo-2/512/visa-64.png"
                            alt=""
                          />
                        </span>
                        <span className="we-acc-ions">
                          <img
                            src="  https://cdn0.iconfinder.com/data/icons/payment-method/480/rupay_payment_card_bank-64.png"
                            alt=""
                          />
                        </span>
                        <span className="we-acc-ions">
                          <img
                            src="  https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/upi-icon.png"
                            alt=""
                          />
                        </span>
                        <span className="we-acc-ions">
                          <img
                            src="   https://cdn2.iconfinder.com/data/icons/social-icons-color/512/paytm-64.png"
                            alt=""
                          />
                        </span>
                        <span className="we-acc-ions">
                          <img
                            src="https://cdn1.iconfinder.com/data/icons/logos-brands-in-colors/436/Google_Pay_GPay_Logo-64.png"
                            alt=""
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <h1 className="empty-bag">Your Shopping Bag is empty!</h1>
            </>
          )}
        </div>
      </div>

      <div>
        <div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <>
              <div className="modal-cart-coupon">
                <div className="close-m2">
                  <span className="para-c-m">Discounts</span>
                  <AiOutlineClose
                    onClick={closeModal}
                    color="black"
                    size={20}
                  />
                </div>
                <div className="coupon-mt-sec">
                  <span className="lable-ccu">Add a discount code</span>
                  <div className="apply-coupon-section">
                    <input className="input-field-coupon" 
                    onChange={(e) => setinputcoupon(e.target.value)}
                    value={inputcoupon}
                    type="text" />
                    {inputcoupon.length>0 ?             <button 
                    
                    onClick={onClickCoupon}
                    
                    className="add-coupon">Add</button>  :            <button 
                    
            
                    
                    className="add-coupon2">Add</button> }
         
                  </div>
                </div>
              </div>
            </>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Cart;
