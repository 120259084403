import React from "react";
import "./Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineHeart } from "react-icons/ai";
import { LiaArrowRightSolid, LiaSearchSolid } from "react-icons/lia";
import { SlUser } from "react-icons/sl";
import { BiUser } from "react-icons/bi";

import { GrMenu } from "react-icons/gr";
import { PiHeartStraight } from "react-icons/pi";

import { BsBag } from "react-icons/bs";
import { Popular } from "../Data/Data1";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../Firebase/Firebase";
import { useSelector } from "react-redux";
import { selectBasketItems } from "../Redux/basketSlice";
import { styled } from "styled-components";

import { GrClose } from "react-icons/gr";
import { useState } from "react";

const StyledOffCanvas = styled.aside`
  position: fixed;
  top: 0;
  left: ${(props) => (props.isOpen ? 0 : "-100%")};
  width: 300px;
  height: 100%;
  background-color: white;
  z-index: 1000;
  color: white;
  transition: left 0.5s ease-out;
`;

const StyledOffCanvasOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 500;
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const Navbar = () => {
  const navigate = useNavigate();

  const [user] = useAuthState(auth);

  const [isOpen, setIsOpen] = useState(false);

  const items = useSelector(selectBasketItems);
  const handleSignOut = () => {
    auth.signOut().catch((error) => alert(error.message));
  };

  return (
    <>
      <div className="nav-main-d">
        <div className="menu-op-ic">
          <GrMenu size={25} onClick={() => setIsOpen(!isOpen)} />
        </div>
        <div className="nav-d1">
          <Link to={"/"}>
            <div className="logo-nav1">
              <img src={require("../../assets/logo.png")} alt="" />
            </div>
          </Link>

          <ul className="nav-ul1 d-none-nav-ul">
            <li className="nav-ul-li">
              <Link to={"/"}>Home</Link>
            </li>
            <div class="dropdown">
              <button class="dropbtn">
                <Link
                  className={({ isActive }) =>
                    isActive ? "NavBarMenuNavlink-active" : "NavBarMenuNavlink"
                  }
                  to={"/Productlist"}
                >
                  <div className="NavBarMenuNavLinkDiv">
                    <p>Shop</p> <span className="NavBarMenuNavLinkSpan"></span>
                  </div>
                </Link>
              </button>
              <div class="dropdown-content">
                {Popular.map((item) => (
                  <div
                    onClick={() =>
                      navigate("/Productlist", { state: { item } })
                    }
                    className=""
                  >
                    <span className="nav-cat-spa">{item.category}</span>
                  </div>
                ))}
              </div>
            </div>
            {/* <span></span>
          <span></span>

          <span></span>

          <span></span>

          <span></span>
    <span></span>      <span></span>

<span></span>
<span></span> */}
          </ul>
        </div>

        <div className="nav-d3">
          <ul className="nav-ul1">
            {user ? (
              <>
                {/* <li className="nav-ul-li">
              <Link className="link-navi-nav" to={"/Account"}>
                <SlUser size={20} />
                <span>My account</span>
              </Link>
            </li> */}

                <div class="dropdown">
                  <button class="dropbtn">
                    <li className="nav-ul-li">
                      <Link className="link-navi-nav" to={"/Account"}>
                        <SlUser size={20} />
                        <span>My account</span>
                      </Link>
                    </li>
                  </button>
                  <div class="dropdown-content">
                    <div onClick={() => navigate("/Account")} className="">
                      <span onClick={handleSignOut} className="nav-cat-spa">
                        Log out
                      </span>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <li className="nav-ul-li">
                <Link to={"/Login"}>Login</Link>
              </li>
            )}

            {/* <li className="nav-ul-li">
            <Link><LiaSearchSolid  size={18}/></Link>
          </li> */}
            <li className="nav-ul-li">
              <Link className="link-navi-nav" to={"/Favourites"}>
                <PiHeartStraight className="svg-nav1" />

                <span>Favourites</span>
              </Link>
            </li>

            <li className="nav-ul-li">
              <Link className="link-navi-nav " to={"/Cart"}>
                <BsBag className="svg-nav2" />
                <span className="sv-cart-cc">{items.length}</span>
                <span>Shopping bag ({items.length}) </span>
              </Link>
            </li>
          </ul>
        </div>
      </div>


      <StyledOffCanvas isOpen={isOpen} className="side-menu-side">
        <div className={isOpen ? "head-mobile-menu" : "none-d-sidenav"}>
          <div className="close-icon-nav">
            <GrClose color="black" size={20} onClick={() => setIsOpen(false)} />
          </div>

          <div className="category-div-nav">
            <div className="cat-secion-nav">
              <div className="cat-div-nav2">
                <div className="cat-div-nav3">
                  <img
                    className="cat-di-img1"
                    src="https://image.hm.com/content/dam/abtestdepartmentimages/Ladies1.png?imwidth=150"
                    alt=""
                  />
                  <span>Ladies</span>
                </div>
                <div>
                  <LiaArrowRightSolid color="black" size={20} />
                </div>
              </div>
              <div className="cat-div-nav2">
                <div className="cat-div-nav3">
                  <img
                    className="cat-di-img1"
                    src="https://image.hm.com/content/dam/abtestdepartmentimages/Men1.png?imwidth=150"
                    alt=""
                  />
                  <span>Men</span>
                </div>
                <div>
                  <LiaArrowRightSolid color="black" size={20} />
                </div>
              </div>
              <div className="cat-div-nav2">
                <div className="cat-div-nav3">
                  <img
                    className="cat-di-img1"
                    src="https://image.hm.com/content/dam/abtestdepartmentimages/Sport1.png?imwidth=150"
                    alt=""
                  />
                  <span>Sport</span>
                </div>
                <div>
                  <LiaArrowRightSolid color="black" size={20} />
                </div>
              </div>
              <div className="cat-div-nav2">
                <div className="cat-div-nav3">
                  <img
                    className="cat-di-img1"
                    src="https://image.hm.com/content/dam/abtestdepartmentimages/Sale1.png?imwidth=150"
                    alt=""
                  />
                  <span>Sale</span>
                </div>
                <div>
                  <LiaArrowRightSolid color="black" size={20} />
                </div>
              </div>
            </div>
            {user ? (
             <div className="cat-div-nav4">
             <Link to={'/Account'}  onClick={() => setIsOpen(false)} >
             <span>My Account</span>
             </Link>
           </div>
            ) : (
              <div className="cat-div-nav4">
                <Link to={'/Login'} onClick={() => setIsOpen(false)} >
                <span>Login / Register</span>
                </Link>
              </div>
            )}
          </div>
        </div>
      </StyledOffCanvas>
      <StyledOffCanvasOverlay
        isOpen={isOpen}
        onClick={() => setIsOpen(false)}
      />
 
    </>
  );
};

export default Navbar;
