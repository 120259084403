import React, {  useState } from "react";
import { Autoplay, EffectFade, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Carousel.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";

const Carousel = () => {
  const swiperRef = React.useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const handleSlideChange = (index) => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(index);
      setActiveSlide(index);
    }
  };

  return (
    <div className="carousel-home-d1">
      <div className="carousel-h-d2">
        <div className="carousel-h-d3">
          <div className="btns-paginations-home">
            {/* <div class="underline-div">
    <span>01</span>
</div> */}

            <div className={activeSlide === 0 ? "pag-btn-s2" : "pag-btn-s1"}>
              <button
                onClick={() => handleSlideChange(0)}
                className={
                  activeSlide === 0
                    ? "active btn-swiper-home"
                    : "btn-swiper-home"
                }
              >
                01
              </button>
              {/* <div
                className={
                  activeSlide === 0
                    ? "line-b-carousel"
                    : "btn-swiper-home"
                }
              ></div> */}
              <span className="pav-span-home"></span>
            </div>
            <div className={activeSlide === 1 ? "pag-btn-s2" : "pag-btn-s1"}>
              <button
                onClick={() => handleSlideChange(1)}
                className={
                  activeSlide === 1
                    ? "active btn-swiper-home"
                    : "btn-swiper-home"
                }
              >
                02
              </button>
              <span className="pav-span-home"></span>
            </div>
            <div className={activeSlide === 2 ? "pag-btn-s2" : "pag-btn-s1"}>
              <button
                onClick={() => handleSlideChange(2)}
                className={
                  activeSlide === 2
                    ? "active btn-swiper-home"
                    : "btn-swiper-home"
                }
              >
                03
              </button>
              <span className="pav-span-home"></span>
            </div>
          </div>

          <div className="carousel-home">
            <Swiper
              centeredSlides={true}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              effect={"fade"}
              className="mySwiper"
              modules={[EffectFade, Autoplay, Pagination]}
              ref={swiperRef}
              pagination={{ el: ".custom-pagination", clickable: true }}
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
              }}
              onSlideChange={(swiper) => {
                setActiveSlide(swiper.activeIndex);

                // Remove animation classes from all slides
                const allSlides = document.querySelectorAll(".swiper-slide");
                allSlides.forEach((slide) => {
                  const imgElement = slide.querySelector(".png-1-homec");
                  const textElement = slide.querySelector(".content-swip-2");
                  imgElement.classList.remove("fadeInUp1");
                  textElement.classList.remove("fadeInRight1");
                });

                // Trigger animations on the current slide
                const currentSlide = swiper.slides[swiper.activeIndex];
                const imgElement = currentSlide.querySelector(".png-1-homec");
                const textElement =
                  currentSlide.querySelector(".content-swip-2");
                imgElement.classList.add("fadeInUp1");
                textElement.classList.add("fadeInRight1");
              }}
            >
              <SwiperSlide>
                <div className="img-carousel-home">
                  <div className="content-swip-section">
                    <div className="content-head-swip1">
                      <div className="content-swip-2">
                        <h1 className="swip-home-h1">PREMIUM COMFORT</h1>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit.
                        </p>
                      </div>
                      <img
                      
                        className="png-1-homec"
                        src={'https://static.nike.com/a/images/c_limit,w_592,f_auto/t_product_v1/u_126ab356-44d8-4a06-89b4-fcdcc8df0245,c_scale,fl_relative,w_1.0,h_1.0,fl_layer_apply/5b15af60-d252-4f49-b5de-5f1e1a981df2/jordan-flight-essentials-t-shirt-JRFdw8.png'}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="img-carousel-home">
                  <div className="content-swip-section">
                    <div className="content-head-swip1">
                      <div className="content-swip-2">
                        <h1 className="swip-home-h1">PREMIUM COMFORT</h1>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit.
                        </p>
                      </div>
                      <img
                      
                        className="png-1-homec"
                        src={'https://static.nike.com/a/images/c_limit,w_592,f_auto/t_product_v1/u_126ab356-44d8-4a06-89b4-fcdcc8df0245,c_scale,fl_relative,w_1.0,h_1.0,fl_layer_apply/fb8d8ab6-a232-4813-876b-67d32ad41233/jordan-sport-graphic-t-shirt-0PM7k2.png'}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="img-carousel-home">
                  <div className="content-swip-section">
                    <div className="content-head-swip1">
                      <div className="content-swip-2">
                        <h1 className="swip-home-h1">PREMIUM COMFORT</h1>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit.
                        </p>
                      </div>
                      <img
                     
                        className="png-1-homec"
                        src={'https://static.nike.com/a/images/c_limit,w_592,f_auto/t_product_v1/u_126ab356-44d8-4a06-89b4-fcdcc8df0245,c_scale,fl_relative,w_1.0,h_1.0,fl_layer_apply/6bde9e9b-4154-4b3f-b093-615bb493505c/jordan-flight-essentials-oversized-t-shirt-C7XVkQ.png'}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
