import React from "react";
import Currency from "react-currency-formatter";
import { useNavigate } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
const Favcards = ({ item, deleteBook }) => {
  const navigate = useNavigate();

  return (
    <>
      <div  className="fav-card-ff1">
        <AiOutlineDelete
          onClick={() => deleteBook(item.id)}
          size={22}
          className="ProductDetailPartIcon"
        />
        <div
          // className="fav-card-ff1"
          onClick={() =>
            navigate(
              "/ProductPreview",

              { state: item }
            )
          }
        >
          <div className="fav-card">
            <div className="ProductDetailPart">
              <img className="fav-card-img" src={item?.image} alt="" />
            </div>
            <h1 className="fav-card-p-name">{item?.productname}</h1>
            <h1 className="fav-card-p-name2">
              <Currency quantity={item?.price} currency="INR" />
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Favcards;
