import React from "react";
import "./Terms.css";
const Term = () => {
  return (
    <div className="bg-conn">
      <h1>Terms and Conditions </h1>
      <div className="ngh-1">
        <div className="ngf-com1">
          
          <p>
            Welcome to Protein Cartel. These terms and conditions (“Terms”)
            govern your use of our website and your purchase of our products in

            
            India. By using our website or purchasing our products, you agree to
            be bound by these Terms. If you do not agree to these Terms, please
            do not use our website or purchase our products.
          </p>
          <h1>General Terms</h1>
          <p>
            You must be at least 18 years old to use our website and purchase
            our products. We reserve the right to modify these Terms at any
            time. Any changes will be posted on our website, and the updated
            Terms will be effective immediately. We reserve the right to refuse
            service to anyone for any reason at any time. Intellectual Property
          </p>
          <p>
            All content on our website, including but not limited to text,
            graphics, logos, images, and software, is the property of Protein
            Cartel and is protected by Indian and international copyright laws.
            You may not use our content for any commercial purpose without our
            prior written consent.
          </p>

          <h1>Product Descriptions</h1>

          <p>
            We make every effort to ensure that the descriptions and images of
            our products are accurate. However, we cannot guarantee that the
            information on our website is error-free or complete. We reserve the
            right to modify our product descriptions and prices at any time
            without notice.
          </p>
          <h1>Pricing and Payment</h1>
          <p>
            The prices of our products are listed on our website and are subject
            to change at any time without notice. Payment for our products must
            be made in full at the time of purchase. We accept major credit
            cards and online payment options available in India. Shipping and
            Delivery
          </p>
          <h1>We ship our products to locations within India only.</h1>

          <p>
            Shipping and handling fees are calculated based on the weight and
            destination of your order. We make every effort to ship your order
            within 1-2 business days of receiving payment. However, we cannot
            guarantee delivery dates.
          </p>
          <h1>Returns and Refunds</h1>

          <p>
            We offer a 4-5-day money-back guarantee on all of our products sold
            in India. Please see our Refund and Cancellation Policy for more
            information. Limitation of Liability
          </p>
          <p>
            Protein Cartel is not liable for any damages, including but not
            limited to direct, indirect, incidental, punitive, and consequential
            damages, arising from your use of our website or your purchase of
            our products. Our liability to you for any claim related to our
            products or services is limited to the purchase price of the product
            or service in question.
          </p>

          <h1>Governing Law</h1>
          <p>
            These Terms are governed by the laws of India, without regard to its
            conflict of laws principles.
          </p>
          <h1>Contact Us</h1>
          <p>
          If you have any questions or concerns about these Terms, please contact us at info@proteincartel.com.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Term;
