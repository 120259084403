import React, { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../components/Firebase/Firebase";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
const Carousel = ({ item ,loading }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [touchStartX, setTouchStartX] = useState(null);
  const [images, setImages] = useState([]);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  const closeFullScreen = () => {
    setSelectedImageIndex(null);
  };

  const handlePrevImage = () => {
    setSelectedImageIndex((prevIndex) => {
      return prevIndex === null
        ? images.length - 1
        : (prevIndex - 1 + images.length) % images.length;
    });
  };

  const handleNextImage = () => {
    setSelectedImageIndex((prevIndex) => {
      return prevIndex === null ? 0 : (prevIndex + 1) % images.length;
    });
  };

  const handleTouchStart = (event) => {
    setTouchStartX(event.touches[0].clientX);
  };

  const handleTouchEnd = (event) => {
    const touchEndX = event.changedTouches[0].clientX;
    const touchThreshold = 50; // Adjust this value as needed

    if (touchStartX - touchEndX > touchThreshold) {
      // Swiped left
      handleNextImage();
    } else if (touchEndX - touchStartX > touchThreshold) {
      // Swiped right
      handlePrevImage();
    }
  };

  useEffect(() => {
    const handleCloseOnOutsideClick = (event) => {
      if (
        selectedImageIndex !== null &&
        event.target.className === "full-screen"
      ) {
        closeFullScreen();
      }
    };

    window.addEventListener("click", handleCloseOnOutsideClick);

    return () => {
      window.removeEventListener("click", handleCloseOnOutsideClick);
    };
  }, [selectedImageIndex]);

  useEffect(() => {
    try {
      const todosRef = collection(
        db,
        "products",
        item?.category,
        item?.type,
        item?.id,
        "images"
      );
      const q = query(todosRef, orderBy("num", "asc"));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const imageArray = [];
        querySnapshot.forEach((doc) => {
          imageArray.push({ id: doc.id, ...doc.data() });
        });
        setImages(imageArray);
      });

      return () => {
        unsubscribe();
      };
    } catch (error) {}
  }, [item]);




  const [userData, setUserData] = useState({}); // Replace with actual user data

  const [loading1, setLoading] = useState(true);
  // useEffect(() => {
  //   // Simulate fetching user data (replace with your API call)
  //   setTimeout(() => {
  //     setUserData({ name: 'John Doe', avatar: 'user.jpg' });
  //     setLoading(false);
  //   }, 2000); // Simulate a 2-second loading delay
  // }, []);

  return (
    <div>



<div className="user-profile">
      <div className="user-card">
        {loading ? (

          
          <>



    
{/* <div className="pro-carousel-section">
        <div className="carousel-pro-v">
          {Array.isArray(images)
            ? images.map((imageItem, index) => (
                <div className="photo-pro-carousel" key={imageItem.id}>
                  <img
                    src={imageItem.image}
                    alt="Product"
                    onClick={() => handleImageClick(index)}
                  />
                </div>
              ))
            : null}

          {selectedImageIndex !== null && (
            <div
              className="full-screen"
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
            >
              <div className="pro-full-c-img">
                <div className="full-screen-inner">
                  <img
                    src={images[selectedImageIndex]?.image}
                    alt="Full-screen"
                    onClick={closeFullScreen}
                  />
                  <div className="image-controls">
                    <div className="img-full-controls1">
                      <span className="prev-image" onClick={handlePrevImage}>
                        <IoIosArrowBack color={"#929292"} size={30} />
                      </span>
                      <span className="next-image" onClick={handleNextImage}>
                        <IoIosArrowForward color={"#929292"} size={30} />
                      </span>
                    </div>
                    <div className="img-full-controls2">
                      <span className="image-number">
                        {selectedImageIndex + 1}/{images.length}
                      </span>
                    </div>
                    <div className="img-full-controls2">
                      <span className="image-number">
                        <AiOutlineClose
                          color={"#929292"}
                          size={30}
                          onClick={closeFullScreen}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="img-1-carosuel">
          <img
            src={item?.image}
            alt="Product"
            onClick={() => handleImageClick(0)}
          />
        </div>
      </div> */}


<div className="pro-carousel-section">
        <div className="carousel-pro-v">
        {/* {Array.isArray(images)
            ? images.map((imageItem, index) => ( */}
                {/* <div className="photo-pro-carousel lh-sket-di1" > */}
                           <Skeleton className="lh-sket-pv" />
                           <Skeleton className="lh-sket-pv" />
                           <Skeleton className="lh-sket-pv" />



                {/* </div> */}
            {/* //   ))
            // : null} */}

   
        </div>
        <div className="img-1-carosuel">
        <Skeleton className="skeleton-1-p1" />

        </div>
      </div>


              
{/* 
            <Skeleton count={3} /> */}
          </>
        ) : (
          <>
          
            <div className="pro-carousel-section">
        <div className="carousel-pro-v">
          {Array.isArray(images)
            ? images.map((imageItem, index) => (
                <div className="photo-pro-carousel" key={imageItem.id}>
                  <img
                    src={imageItem.image}
                    alt="Product"
                    onClick={() => handleImageClick(index)}
                  />
                </div>
              ))
            : null}

          {selectedImageIndex !== null && (
            <div
              className="full-screen"
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
            >
              <div className="pro-full-c-img">
                <div className="full-screen-inner">
                  <img
                    src={images[selectedImageIndex]?.image}
                    alt="Full-screen"
                    onClick={closeFullScreen}
                  />
                  <div className="image-controls">
                    <div className="img-full-controls1">
                      <span className="prev-image" onClick={handlePrevImage}>
                        <IoIosArrowBack color={"#929292"} size={30} />
                      </span>
                      <span className="next-image" onClick={handleNextImage}>
                        <IoIosArrowForward color={"#929292"} size={30} />
                      </span>
                    </div>
                    <div className="img-full-controls2">
                      <span className="image-number">
                        {selectedImageIndex + 1}/{images.length}
                      </span>
                    </div>
                    <div className="img-full-controls2">
                      <span className="image-number">
                        <AiOutlineClose
                          color={"#929292"}
                          size={30}
                          onClick={closeFullScreen}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="img-1-carosuel">
          <img
            src={item?.image}
            alt="Product"
            onClick={() => handleImageClick(0)}
          />
        </div>
      </div>






          
          </>
        )}
      </div>
    </div>



      {/* {loading ?  <div className="pro-carousel-section">
        <div className="carousel-pro-v">
  

<div className="skeleton">
  <div className="skeleton-img">

  </div>
  <div className="skeleton-img">

  </div>
  <div className="skeleton-img">

  </div>

</div>


      
        </div>
        <div className="img-1-carosuel22">

</div>



      </div>  : 
      
      <div className="pro-carousel-section">
        <div className="carousel-pro-v">
          {Array.isArray(images)
            ? images.map((imageItem, index) => (
                <div className="photo-pro-carousel" key={imageItem.id}>
                  <img
                    src={imageItem.image}
                    alt="Product"
                    onClick={() => handleImageClick(index)}
                  />
                </div>
              ))
            : null}

          {selectedImageIndex !== null && (
            <div
              className="full-screen"
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
            >
              <div className="pro-full-c-img">
                <div className="full-screen-inner">
                  <img
                    src={images[selectedImageIndex]?.image}
                    alt="Full-screen"
                    onClick={closeFullScreen}
                  />
                  <div className="image-controls">
                    <div className="img-full-controls1">
                      <span className="prev-image" onClick={handlePrevImage}>
                        <IoIosArrowBack color={"#929292"} size={30} />
                      </span>
                      <span className="next-image" onClick={handleNextImage}>
                        <IoIosArrowForward color={"#929292"} size={30} />
                      </span>
                    </div>
                    <div className="img-full-controls2">
                      <span className="image-number">
                        {selectedImageIndex + 1}/{images.length}
                      </span>
                    </div>
                    <div className="img-full-controls2">
                      <span className="image-number">
                        <AiOutlineClose
                          color={"#929292"}
                          size={30}
                          onClick={closeFullScreen}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="img-1-carosuel">
          <img
            src={item?.image}
            alt="Product"
            onClick={() => handleImageClick(0)}
          />
        </div>
      </div>
      
      } */}
     
    </div>
  );
};

export default Carousel;
