import React from "react";
import "./Productlist.css";
import Productlistcards from "./Productlistcards";
const Productlist = () => {
  return (
    <div className="pro-list-p1">
      <div className="pro-list-p2">
        <div className="pro-list-p4">
          <h1 className="pro-list-head">Tops</h1>
          <h1 className="fav-head2">1 Item</h1>

        </div>
        <div className="pro-list-p3">
          <Productlistcards/>
        </div>
      </div>
    </div>
  );
};

export default Productlist;
