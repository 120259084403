import React, { useState } from 'react'
import './Home.css'
import Carousel from '../../components/Carousel/Carousel'
import Category from '../../components/Category/Category'
import { BeatLoader } from "react-spinners";
import { useEffect } from 'react';

const Home = () => {


  const [isLoading, setIsLoading] = useState(true);

useEffect(() => {
  setTimeout(() => {
    setIsLoading(false)
    
  }, 2000);

}, [])

  if (isLoading) {
    return (
      <>
        <div className="loader-sp">
          <BeatLoader size={15} color={"black"} loading={isLoading} />
        </div>
      </>
    );
  }


  return (
    <div className='mt-home1'>
<Carousel/>
<Category/>

    </div>
  )
}

export default Home