import React, { useEffect, useState } from "react";
import "./Category.css";
import { AiOutlineCaretDown } from "react-icons/ai";
import CategoryCard from "./CategoryCard";
import { collection, onSnapshot } from "firebase/firestore";
import { auth, db } from "../../components/Firebase/Firebase";
import Skeleton from "react-loading-skeleton";
const Catrgory = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    try {
      const q = collection(db, "products", "top", "t-shirts");

      onSnapshot(q, (querySnapshot) => {
        const cities = [];
        querySnapshot.forEach((doc) => {
          cities.push({ id: doc.id, ...doc.data() });
        });
        setProducts(cities);
        setIsLoading(false)
      });
    } catch {}
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [Loading, setLoading] = useState(true);


  // console.log(products)

  return (
    <div className="cat-home1">
      <div className="cat-home2">
        <div className="cat-home3">
          <div className="cat-df-1">
            <ul className="cat-ul">
              <li className="cat-li">All</li>
              <li className="cat-li">TOPS</li>
              <li className="cat-li">JEANS</li>
            </ul>

            <h1 className="cat-home-h1">
              <span>Filter</span>
              <span>
                <AiOutlineCaretDown />
              </span>
            </h1>
          </div>
          {/* Loading */}
          {isLoading ? (
                         <div >
       {/* {Array.isArray(products)
                ? products.map((item, key) => {
                    return (
                      <>
    
                      </>
                    );
                  })
                : null} */}
     <div className="cards-section-cat-home1">
          <div className="card-rel-c1">
            <div>
              <div className="card-cat-re">
      
                  <Skeleton  className="card-ske-s" />

              </div>
              <div className="related-con-c1">
                <h1>
                  <Skeleton count={1} />
                </h1>
                <h2>
          
                  <Skeleton count={1} />
                </h2>
              </div>
            </div>
          </div>
          <div className="card-rel-c1">
            <div>
              <div className="card-cat-re">
      
                  <Skeleton  className="card-ske-s" />

              </div>
              <div className="related-con-c1">
                <h1>
                  <Skeleton count={1} />
                </h1>
                <h2>
          
                  <Skeleton count={1} />
                </h2>
              </div>
            </div>
          </div>
          <div className="card-rel-c1">
            <div>
              <div className="card-cat-re">
      
                  <Skeleton  className="card-ske-s" />

              </div>
              <div className="related-con-c1">
                <h1>
                  <Skeleton count={1} />
                </h1>
                <h2>
          
                  <Skeleton count={1} />
                </h2>
              </div>
            </div>
          </div>
        </div>
          
            </div>
          ) : (
            <div className="card-s-cat">
              {Array.isArray(products)
                ? products.map((item, key) => {
                    return (
                      <>
                        <CategoryCard item={item} />
                      </>
                    );
                  })
                : null}
            </div>
          )}
       {/* <div className="card-s-cat">
              {Array.isArray(products)
                ? products.map((item, key) => {
                    return (
                      <>
                        <CategoryCard item={item} loding={isLoading} />
                      </>
                    );
                  })
                : null}
            </div> */}


    
        </div>
      </div>
    </div>
  );
};

export default Catrgory;
