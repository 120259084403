import React, { useState, usesize } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

const CategoryCard = ({ item, loading }) => {
  const navigation = useNavigate();
  const [selectedsize, setSelectedsize] = useState("");

  const handlesizeChange = (e) => {
    setSelectedsize(e.target.value);
  };

  const sizes = ["S", "M", "L", "XL"];
  const [loading1, setLoading] = useState(true);

  return (
    <>

        <div className="cards-section-cat-home">
          <div className="card-rel-c1">
            <div className="card-car-ab-1">
              <div className="card-select-size">
                <select
                  id="sizeSelect"
                  value={selectedsize}
                  onChange={handlesizeChange}
                >
                  <option value=""> Select size</option>
                  {sizes.map((size, index) => (
                    <option key={index} value={size}>
                      Size:{size}
                    </option>
                  ))}
                </select>
              </div>
              <button className="add-card-btn-1">Add</button>
            </div>
            <div
              className="card-cat-1"
              onClick={() => navigation("/ProductPreview", { state: item })}
            >
              <div className="card-cat-re">
                <img className="card-cat-img-1" src={item?.image} alt="" />
              </div>
              <div className="related-con-c1">
                <h1>{item?.name}</h1>
                <h2>MRP : ₹{item?.price}</h2>
              </div>
            </div>
          </div>
        </div>
  
    </>
  );
};

export default CategoryCard;
