import React, { useEffect, useState } from "react";
import "./ProductPrev.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import Carousel from "./ProCarousel";
import ProductDetail from "./ProductDetail";
import Related from "./Related";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../components/Firebase/Firebase";
import { BeatLoader } from "react-spinners";
import toast from "react-hot-toast";





const ProductPrev = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const item = location.state;

  const [items, setItems] = useState();

  useEffect(() => {
    getDoc(doc(db, "products", item?.category, item?.type, item?.id)).then(
      (docSnap) => {
        if (docSnap.exists()) {
          setItems(docSnap.data());
          setIsLoading(false);
          // setIsLoading2(false);
        } else {
          console.log("No such document!");
        }
      }
    );
  }, []);
  // console.log(items?.id)

  // const [item, setItem] = useState();

  // useEffect(() => {
  //   getDoc(doc(db, "products", from?.category,from?.type.from?.id)).then((docSnap) => {
  //     if (docSnap.exists()) {
  //       setItem(docSnap.data());

  //     } else {
  //       console.log("No such document!");
  //     }
  //   });
  // }, []);
  // console.log(item)

  const size = ["s", "m", "l", "xl", "xxl"];
  const color = ["black", "white"];

  const itemsPerPage = 3;
  const [currentPage, setCurrentPage] = useState(1);

  // Define your list of items
  // const items = [
  //   // Your item data here
  // ];

  // // Calculate the range of items to display on the current page
  // const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;

  // // Filter the items to display on the current page
  // const itemsToDisplay = items.slice(startIndex, endIndex);

  // // Function to handle changing the current page
  // const handlePageChange = (newPage) => {
  //   setCurrentPage(newPage);
  // };















  // const [isLoading2, setIsLoading2] = useState(true);

  // useEffect(() => {
  //   setTimeout(() => {
  //     // setIsLoading2(false)
  //   }, 1000);
  // }, []);

  const [isLoading, setIsLoading] = useState(true);

  if (isLoading) {
    return (
      <>
        <div className="loader-sp">
          <BeatLoader size={15} color={"black"} loading={isLoading} />
        </div>
      </>
    );
  }

  return (
    <div className="pro-view1">
      <div className="pro-view2">
        <div className="pre-view3">
          <div className="pre-view-c1">
            <Link to={"/"}>
              <span>Home</span>
            </Link>
            <span>/</span>
            <span>ProductPreview</span>
          </div>
          <div className="pro-view4">
            <Carousel item={items} loading={isLoading} />
            <ProductDetail item={items}  loading={isLoading}/>
          </div>
          <div className="related-section-head">
            <h1 className="related-h1">Related Products</h1>
            <ScrollContainer>
              <Related />
            </ScrollContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPrev;
