import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home/Home";

import { Toaster } from "react-hot-toast";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import ProductPrev from "./Pages/Product/ProductPrev";
import Cart from "./Pages/Cart/Cart";
import Checkout from "./Pages/Checkout/Checkout";
import ScrollToTop from "./components/ScrollToTop";
import Account from "./Pages/Account/Account";
import Login from "./Pages/Login/Login";
import Favourites from "./Pages/Favourites/Favourites";
import Productlist from "./Pages/Productlist/Productlist";
import store from "./Store";
import { Provider } from "react-redux";
import Shipping from "./Pages/Term&Conditions/Shipping";

import Privacy from "./Pages/Term&Conditions/Privacy";
import Refund from "./Pages/Term&Conditions/Refund";
import Term from "./Pages/Term&Conditions/Term";
import Contact from "./Pages/Contact/Contact";
const App = () => {
  
  return (
    <>
            <Provider store={store}>
            <Router>
        <Toaster />
        <ScrollToTop/>

        <Navbar />

        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route exact path="/ProductPreview" element={<ProductPrev />} />
          <Route exact path="/Cart" element={<Cart/>} />
          <Route exact path="/Checkout" element={<Checkout/>} />
          <Route exact path="/Account" element={<Account/>} />
          <Route exact path="/Login" element={<Login/>} />
          <Route exact path="/Favourites" element={<Favourites/>} />
          <Route exact path="/Productlist" element={<Productlist/>} />
          <Route exact path="/Contact-us" element={<Contact/>} />

    
          <Route path="/Privacy-Policy" element={<Privacy />} />
          <Route path="/Refund-Shipping-Policy" element={<Refund />} />
          <Route path="/Terms-Conditions" element={<Term />} />
          <Route path="/Shipping&DeliveryPolicy" element={<Shipping />} />





        </Routes>
        <Footer />
      </Router>
            </Provider>

    
    </>
  );
};

export default App;
